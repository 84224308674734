<template>
  <div id="dashboard" class="pa-4">
    <v-container fluid>
      <!-- <div class="d-flex justify-space-between mb-5">
        <h2></h2>
        <div class="right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                @click="dialog = true"
                class="mr-4 white"
                icon
                v-on="{ ...tooltip }"
                ><v-icon size="25" class="small">mdi-upload</v-icon></v-btn
              >
            </template>
            <span>Upload video</span>
          </v-tooltip>
        </div>
      </div> -->
      <v-row> 
        <v-col v-if="subscribeLength === false" cols="12" sm="12" md="12">
          No data available
        </v-col>
        
        <v-col v-else cols="12" sm="12" md="12">
          <v-card class="mx-auto" outlined>
            <v-card-title class="pl-5">Recent subscribers</v-card-title>

            <v-card-actions class="d-block ml-2">
              
              <!-- <v-btn color="blue" text @click="subscribersDialog = true">
                See all
              </v-btn> -->

               <v-card tile>
                <!-- <div class="d-flex justify-space-between mb-5">
                  <v-card-title class="py-3">Subscribers</v-card-title>

                  <v-btn icon text @click="closeModal">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div> -->

                <v-card-text class="px-3">
                  <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 px-0">
                      <v-data-table
                        :headers="headers"
                        :items="subscribers"
                        :loading="loading"
                        sort-by="calories"
                      >
                        <template v-slot:item.channel="{ item }">
                          <v-avatar size="35" color="red" class="white--text">
                            <template
                              v-if="item.subscriberId.photoUrl !== 'no-photo.jpg'"
                            >
                              <img v-if="item.subscriberId.photoUrl" :src=" `${item.subscriberId.photoUrl}`" :alt="`${item.subscriberId.photoUrl} avatar`"
                              />
                              <img v-else :src=" `${getUrl}/no_image.jpg`" :alt="`${item.subscriberId.photoUrl} avatar`"
                              />
                            </template>
                            <span v-else class="headline">
                              {{
                                item.subscriberId.channelName.split('')[0].toUpperCase()
                              }}
                            </span>
                          </v-avatar>
                          <span class="pl-2">{{ item.subscriberId.channelName }}</span>
                        </template>
                        <!-- <template v-slot:item.actions="{ item }">
                          <v-btn
                            text
                            small
                            class="mr-2 blue--text text--darken-4"
                            @click="subscribe(item)"
                          >
                            Subscribe
                          </v-btn>
                        </template> -->
                        <template v-slot:no-data>
                          <v-btn color="primary" @click="initialize">Reset</v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>


            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <upload-video-modal
      :open-dialog="dialog"
      v-on:closeDialog="dialog = false"
    />
    <subscribers-modal
      :open-dialog="subscribersDialog"
      @closeDialog="subscribersDialog = false"
    />
  </div>
</template>

<script>
import UploadVideoModal from '@/components/UploadVideoModal'
// import SubscribersModal from '@/components/SubscribersModal'
import { mapGetters } from 'vuex'
import SubscriptionService from '@/services/SubscriptionService'

export default {
  data: () => ({
    loading: true,
    dialog: false,
    subscribersDialog: false,
    headers: [
        {
          text: 'Channel',
          align: 'start',
          value: 'channel'
        },
        { text: 'Date Subscribed', value: 'createdAt' },
        { text: 'Subcriber count', value: 'subscriberId.subscribers' }
        // { text: 'Actions', value: 'actions', align: 'end', sortable: false }
      ],
      subscribers: [],
      subscribeLength: true
  }),
  computed: {
    ...mapGetters(['getUrl']),
    dialog() {
      return this.openDialog
    }
  },
  components: {
    UploadVideoModal,
    // SubscribersModal
  },
  methods:{
    async getSubscribers() {
      this.loading = true
      const subscribers = await SubscriptionService.getSubscribers(0)
        .catch((err) => console.log(err))
        .finally(() => {
          this.loading = false
        })

      if (!subscribers) return
      this.subscribers = subscribers.data.data
      if(this.subscribers.length > 0){
        this.subscribeLength = true
      }else{
        this.subscribeLength = false
      }

    },
    subscribe(item) {
      console.log(item)
    },
    closeModal() {
      this.$emit('closeDialog')
    }
  },
  mounted() {
    this.getSubscribers()
  }
}
</script>

<style lang="scss">
.card {
  background: #f9f9f9 !important;
}
</style>
